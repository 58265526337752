import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {RGB} from '@Utils/color.util';
import {rgbToHexString} from '@Utils/color.util';
import type * as Fabric from '@postermywall/fabricjs-2';
import {Shadow} from '@postermywall/fabricjs-2';

export const DEFAULT_SHADOW_DISTANCE = 6;
export const DEFAULT_SHADOW_BLUR = 2;
export const DEFAULT_SHADOW_ANGLE = 45;
export const STRONG_SHADOW_COLOR_ALPHA = 0.5;
export const LIGHT_SHADOW_COLOR_ALPHA = 0.25;

export enum AuraType {
  NONE = 0,
  LIGHT_SHADOW = 1,
  STRONG_SHADOW = 2,
  CUSTOM_SHADOW = 3,
  LIGHT_GLOW = 4,
  STRONG_GLOW = 5,
}

export interface ItemAuraObject {
  type: number;
  dropShadowColor: RGB;
  dropShadowAlpha: number;
  dropShadowAngle: number;
  glowColor: RGB;
}

interface GetItemAuraOpts {
  shadowDistance?: number;
  shadowBlur?: number;
  glowVal?: number;
}

export interface ItemShadowProperties {
  type: AuraType;
  dropShadowAlpha?: number;
  dropShadowAngle?: number;
}

export class ItemAura extends CommonMethods {
  public type: AuraType = AuraType.NONE;
  public glowColor: RGB = [255, 255, 255];

  public dropShadowColor: RGB = [0, 0, 0];
  public dropShadowAlpha = 0.25;
  public dropShadowAngle = 45;

  public toObject(): ItemAuraObject {
    return {
      type: this.type,
      dropShadowColor: this.dropShadowColor,
      dropShadowAlpha: this.dropShadowAlpha,
      dropShadowAngle: this.dropShadowAngle,
      glowColor: this.glowColor,
    };
  }

  public getItemAura({
    shadowDistance = DEFAULT_SHADOW_DISTANCE,
    shadowBlur = DEFAULT_SHADOW_BLUR,
    glowVal = this.getDefaultGlowValue(),
  }: GetItemAuraOpts = {}): Fabric.Shadow | null {
    const alpha = this.getColorAlpha();
    if (this.isShadow()) {
      const angle = this.getAngleForShadow();
      let dx = shadowDistance;
      let dy = shadowDistance;
      let colorHex = rgbToHexString([0, 0, 0], alpha);

      if (this.type === AuraType.CUSTOM_SHADOW) {
        colorHex = rgbToHexString(this.dropShadowColor, alpha);
        dx = Math.round((shadowDistance + 2) * Math.cos((angle * Math.PI) / 180));
        dy = Math.round((shadowDistance + 2) * Math.sin((angle * Math.PI) / 180));
      }

      return new Shadow({
        color: colorHex,
        blur: shadowBlur,
        offsetX: dx,
        offsetY: dy,
        affectStroke: true,
      });
    }

    if (this.isGlow()) {
      const co = rgbToHexString(this.glowColor, alpha);
      return new Shadow(`0px 0px ${glowVal}px ${co}`);
    }

    return null;
  }

  public getDefaultGlowValue(): number {
    if (!this.isGlow()) {
      return 0;
    }

    switch (this.type) {
      case AuraType.LIGHT_GLOW:
        return 8;

      case AuraType.STRONG_GLOW:
        return 10;

      default:
        throw new Error(`Unhandled glow type for getDefaultGlowValue: ${this.type}`);
    }
  }

  private getAngleForShadow(): number {
    switch (this.type) {
      case AuraType.STRONG_SHADOW:
      case AuraType.LIGHT_SHADOW:
        return 45;
      case AuraType.CUSTOM_SHADOW:
        return this.dropShadowAngle;
      default:
        throw new Error(`Unhandled shadow type for getColorAlphaForShadow: ${this.type}`);
    }
  }

  private getColorAlpha(): number {
    switch (this.type) {
      case AuraType.STRONG_GLOW:
        return 1;
      case AuraType.LIGHT_GLOW:
        return 0.8;
      case AuraType.STRONG_SHADOW:
        return STRONG_SHADOW_COLOR_ALPHA;
      case AuraType.LIGHT_SHADOW:
        return LIGHT_SHADOW_COLOR_ALPHA;
      case AuraType.CUSTOM_SHADOW:
        return this.dropShadowAlpha;
      case AuraType.NONE:
        return 1;
      default:
        throw new Error(`Unhandled shadow type for getColorAlpha: ${this.type}`);
    }
  }

  public isShadow(): boolean {
    return this.type === AuraType.LIGHT_SHADOW || this.type === AuraType.STRONG_SHADOW || this.type === AuraType.CUSTOM_SHADOW;
  }

  public isGlow(): boolean {
    return this.type === AuraType.LIGHT_GLOW || this.type === AuraType.STRONG_GLOW;
  }

  public hasAura(): boolean {
    return this.type !== AuraType.NONE;
  }

  public isCustomShadow(): boolean {
    return this.type === AuraType.CUSTOM_SHADOW;
  }
}

export const isAuraTypeGlow = (auraType: AuraType): boolean => {
  return auraType === AuraType.LIGHT_GLOW || auraType === AuraType.STRONG_GLOW;
};

export const getShadowObjectFromAuraType = (type: AuraType): ItemShadowProperties => {
  switch (type) {
    case AuraType.CUSTOM_SHADOW:
      return {
        type: AuraType.CUSTOM_SHADOW,
        dropShadowAlpha: 0.25,
        dropShadowAngle: 45,
      };
    case AuraType.STRONG_SHADOW:
      return {
        type: AuraType.STRONG_SHADOW,
        // We need to keep dropShadowAlpha & type in snyc for two reasons
        // 1) If this poster is opened in old builder then dropShadowAlpha governs the shadow amount and not the type
        // 2) There are posters in json saved that have inconsistent dropShadowAlpha and type. Right for now those we
        // change their types in postermodel/objectToGraphicItemCommonProps to match the dropShadowAlpha for old builder.
        // So remove these two values with caution
        dropShadowAlpha: 0.5,
        dropShadowAngle: 45,
      };
    case AuraType.LIGHT_SHADOW:
      return {
        type: AuraType.LIGHT_SHADOW,
        // We need to keep dropShadowAlpha & type in snyc for two reasons
        // 1) If this poster is opened in old builder then dropShadowAlpha governs the shadow amount and not the type
        // 2) There are posters in json saved that have inconsistent dropShadowAlpha and type. Right for now those we
        // change their types in postermodel/objectToGraphicItemCommonProps to match the dropShadowAlpha for old builder.
        // So remove these two values with caution
        dropShadowAlpha: 0.25,
        dropShadowAngle: 45,
      };
    case AuraType.NONE:
      return {
        type: AuraType.NONE,
      };
    default:
      throw new Error('This function only supports shadow aura types.');
  }
};
